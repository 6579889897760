<template>

    <div class="sports-links">
        <router-link tag="a" to="/login" class="link" active-class="active_sports_link">로그인</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/register_term" class="link" active-class="active_sports_link">회원가입</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/findpwd" class="link" active-class="active_sports_link">ID/PW 찾기</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/realnameauth" class="link" active-class="active_sports_link">실명인증</router-link>
    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";

    export default {
        name: "LoginLinksComp",
        props: {
            displayPosition: {
                type: Number,
                default() {
                    return sportsConst.GAME_DISPLAYPOSITION_1x2;
                }
            },
        },
        data() {
            return {
                sportsConst,
                disp: this.displayPosition ? this.displayPosition : sportsConst.GAME_DISPLAYPOSITION_1x2,
            }
        },
        methods: {
            changeSportsDisplay(disp) {
                //SportsOriginal.vue -> $on
                this.disp = disp
                this.$store.commit(RECEIVE_DISPLAY_POSTION, disp);
            }
        },
    }
</script>

<style scoped>
    .sports-links {
       width: 25%;
    }
</style>