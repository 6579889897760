<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <login-links-comp></login-links-comp>
                <div class="logo_panel" style="padding-top: 50px">
                    <div class="logo">
                        <img src="../../assets/images/logo/oneplay/oneplay_logo.png">
                    </div>
                    <div class="ment line">
                        스포츠 게임 <span style="color: #079e39">원플레이</span>에 오신것을 환영합니다.
                    </div>
                    <div class="ment mt10" style="font-size: 12px;color: #c4c4c3">
                        <p>국내 ONEPLAY는 만 18세 이상만 이용 가능한 서비스입니다. (미성년자는 가입할 수 없습니다)</p>
                        <p>원플레이에서는 회원 당 1개의 계정만 가입이 가능합니다.</p>
                    </div>

                    <div class="terms_box">
                        <div class="terms_panel">
                            <div class="item">
                                <div class="item_height">
                                    <img src="../../assets/images/login/signup_icon_1.png">
                                </div>

                                <div class="term_text">
                                    <span class="process_text">1</span>약관동의
                                </div>
                            </div>
                            <div class="item border_left">
                                <div class="item_height">
                                    <img src="../../assets/images/login/signup_icon_2.png">
                                </div>
                                <div class="term_text">
                                    <span class="process_text">2</span>본인인증
                                </div>
                            </div>
                            <div class="item border_left">
                                <div class="item_height">
                                    <img src="../../assets/images/login/signup_icon_3.png">
                                </div>
                                <div class="term_text">
                                    <span class="process_text">3</span>정보입력
                                </div>
                            </div>
                            <div class="item border_left">
                                <div class="item_height">
                                    <img src="../../assets/images/login/signup_icon_4.png">
                                </div>
                                <div class="term_text">
                                    <span class="process_text">4</span>가입완료
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="terms_box">
                        <div class="terms_panel_2">
                            <div class="t1">
                                <div style="line-height: 26px;color: #c4c4c3">서비스 이용약관</div>
                                <div v-html="tearm1Content" class="tearmContent">

                                </div>
                                <div style="line-height: 26px;color: #c4c4c3; font-size: 11px">
                                    <input id="tearm1" type="checkbox" v-model="tearm1" @click="schk(tearm1)">
                                    <label  for="tearm1"> 위의 '서비스 이용약관'에 동의합니다.</label>
                                </div>
                            </div>
                            <div class="t1">
                                <div style="line-height: 26px;color: #c4c4c3">개인정보 수집 및 이용</div>
                                <div v-html="tearm2Content" class="tearmContent">

                                </div>
                                <div style="line-height: 26px;color: #c4c4c3; font-size: 11px">
                                    <input id="tearm2" type="checkbox" v-model="tearm2" @click="schk(tearm2)">   <label  for="tearm2"> 위의 '개인정보
                                    수집 및 이용'에 동의합니다.</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center;font-size: 11px; color: #9f9fa1;box-sizing: border-box;padding: 2px 5px">
                        회원정보는 개인정보취급방침에 따라 안전하게 보호되며 회원님의 명백한 동의 없이 공개 또는 제3자에게 제공되지 않습니다.
                    </div>
                    <div class="buttons">
                        <button class="btn" @click="allow()" style="color: #f4c600"><i class="fa fa-check-square"></i> 동의하기</button>
                        <router-link to="/main" tag="button" class="btn"><i class="fa fa-close"></i> 취소</router-link>
                    </div>

                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>
        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import LoginLinksComp from "../../components/links/LoginLinksComp";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import {getServiceMentList} from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";

    export default {
        name: "RegisterTerm",
        components: {
            SportsLeftBarComp,
            LeftBarComp, TopbarComp, LoginLinksComp, FootComp, RightBarBannerComp, RightBarComp
        },
        data() {
            return {
                tearm1: false,
                tearm2: false,
                tearm1Content:'',
                tearm2Content:'',

            }
        },
        methods: {
            schk(val) {
                val = !val;
            },
            allow(){
                if(!this.tearm1){
                    alert('서비스 이용약관 약관 동의 하셔야만 가입 가능합니다.')
                    return false
                }
                if(!this.tearm2){
                    alert('개인정보 수집 및 이용 약관 동의 하셔야만 가입 가능합니다.')
                    return false
                }
                this.$router.push('/register')
            },
            getServiceMent(){
                getServiceMentList(sportsConst.TYPE_SERVICE_YAKGUAN).then(res=>{
                    if(res.data.success){
                        try{
                            this.tearm1Content = res.data.data.content
                        }catch (e) {

                        }
                    }
                })
                getServiceMentList(sportsConst.TYPE_PERSION_INFO).then(res=>{
                    if(res.data.success){
                        try{
                            this.tearm2Content = res.data.data.content
                        }catch (e) {

                        }
                    }
                })
            }

        },
        created() {
            this.getServiceMent();
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/lore.css");



</style>